import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import Header from "./../../components/Header";
import EventBanner from "./EventBanner";
import Calendar from "./Calendar";
import CalendarFix from "./Calendar/calendarFix";
import MapContainer from "./MapContainer/MapContainer";
import MapContainer2 from "./MapContainer/MapContainer2";
import Footer from "./../../components/Footer";
import { SkeletonHome } from "../../components/Skeleton";
import TimedOut from "../../components/TimedOut";
import Services from "../../services/Services";
import ModalShare from "../../components/ModalShare";
import GoTop from "../../components/GoTop";
import { currencyFormatDE, currencyFormatValue } from "../../utils/globals";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { AngleUp, Map, Place } from "../../utils/iconsSvg";
import moment from "moment";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      imgEvent: "",
      selectedDay: "",
      linkFunc: "",
      showModal: false,
      eventId: null,
      config: null,
      openAdditional: [],
    };
  }

  componentDidMount() {
    const { eventId } = this.props.match.params;
    this.setState({ eventId: eventId });
    const params = new URLSearchParams(this.props.location.search);
    this.setState({
      imgEvent: process.env.REACT_APP_EVENT_IMG
        ? process.env.REACT_APP_EVENT_IMG.replace("{{EVENT_ID}}", eventId)
        : "",
    });
    this.props.getEvent(eventId, params.get("selectedFunctionId"));
    if (this.props.reservationSeats.length > 0) {
      this.props.reservationSeats.forEach(
        async (rs) => await this.cancelReservation(rs)
      );
    }
  }

  cancelReservation = async (item) => {
    try {
      const cancelReservation = await Services.reservations.cancelReservation(
        item.Id
      );
      if (cancelReservation.status === 200) {
        this.props.deleteReservation(item);
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleAdditional = (idx) => {
    if (this.state.openAdditional.find((o) => o === idx)) {
      this.setState({
        openAdditional: this.state.openAdditional.filter((o) => o !== idx),
      });
    } else {
      this.setState({ openAdditional: [...this.state.openAdditional, idx] });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.event.Configuracion !== prevProps.event.Configuracion) {
      if (
        this.props.event &&
        this.props.event.Configuracion &&
        this.props.event?.Configuracion.trim() !== ""
      ) {
        // console.log(this.props.event.Configuracion);
        let data = JSON.parse(this.props.event.Configuracion.trim());
        let close = [];
        if (data.PixelEventID && data.PixelEventID.trim() !== "") {
          ReactPixel.init(data.PixelEventID, {
            autoConfig: true,
            debug: true,
          });
          ReactPixel.pageView();
          ReactPixel.track("ViewContent");
        }
        if (data.AdditionalInfo.length < 4) {
          data.AdditionalInfo.forEach((element, idx) => {
            if (idx !== 0) {
              // close.push(element.name);
            }
          });
        } else {
          data.AdditionalInfo.forEach((element, idx) => {
            close.push(element.name);
          });
        }
        console.log(JSON.parse(this.props.event.Configuracion));
        console.log(close);
        this.setState({
          config: JSON.parse(this.props.event.Configuracion),
          openAdditional: close,
        });
      }
    }
  }

  handleDayClick(day, { selected }) {
    this.setState({ selectedDay: day });
  }

  isMultiCity = () => {
    const { functions } = this.props;
    let retValue = false;
    if (functions && functions.length > 0) {
      if (functions.length === 1) {
        return retValue;
      } else {
        let cities = functions.map((f) => {
          return f.CiudadNombre;
        });
        let unique = cities.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        return unique.length > 1;
      }
    }
    return retValue;
  };

  shortUrl = async () => {
    const resp = await Services.share.getShortenUrl(window.location.href);
    if (resp.status === 200)
      this.setState({ ...this.state, linkFunc: resp.data });
  };

  setShowModal = (value) => {
    this.setState({ ...this.state, showModal: value });
  };
  convertCurrency = () => {
    if (this.props.global.MonedaPorDefecto === "VES") {
      this.props.changeCurrency("USD");
    } else {
      this.props.changeCurrency("VES");
    }
  };

  render() {
    const {
      event,
      eventData,
      locations,
      functions,
      setFunction,
      selectedFunction,
      loading,
      global,
    } = this.props;
    const nameToUrl = selectedFunction?.EventoNombre.replace(/\s/g, "-")
      .normalize("NFD")
      .replace(/[#?+&'\u0300-\u036f]/g, "")
      .toLowerCase();
      let currencySelectedPrice =''
      if(selectedFunction){
          currencySelectedPrice = selectedFunction.Iso ===
              "VES"
                  ? global.MonedaPorDefecto ===
                      "VES"
                      ? `${
                              selectedFunction.MonedaSimbolo ||
                              ""
                          } 
                          ${
                              selectedFunction.MenorPrecio &&
                              currencyFormatDE(
                                  selectedFunction.MenorPrecio,
                                  global.thousandsSeparator,
                                  global.decimalSeparator,
                                  global.MostrarDecimales
                              )
                          } ${
                              eventData &&
                              !eventData.currencyMulti
                                  ? ""
                                  : selectedFunction.Iso
                          }`
                      : selectedFunction.MenorPrecio &&
                          `$ ${currencyFormatDE(
                              currencyFormatValue(
                                  global.dolarToday,
                                  selectedFunction.MenorPrecio,
                                  global.MostrarDecimales
                              ),
                              global.thousandSeparator,
                              global.decimalSeparator,
                              global.MostrarDecimales
                          )} ${
                              global.MonedaPorDefecto
                          }`
                  : `${
                          selectedFunction.MonedaSimbolo ||
                          ""
                      } ${
                          selectedFunction.MenorPrecio &&
                          currencyFormatDE(
                              selectedFunction.MenorPrecio,
                              global.housandSeparator,
                              global.decimalSeparator,
                              global.MostrarDecimales
                          )
                      } ${
                          eventData &&
                          !eventData.currencyMulti
                              ? ""
                              : selectedFunction.Iso
                      }`
      }
    return (
      <>
        <span
          className="bg-top"
          style={{
            backgroundImage: `url(${global.BannerUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          {/* {global.BannerImagen && global.BannerUrl.trim() !== "" && (
                        <img
                            className="bg-top"
                            src={global.BannerUrl}
                            alt=""
                        ></img>
                    )} */}
        </span>
        <GoTop />
        <Header />
        <section id="main" className="mainE">
          <div className="container-custom-tm">
            {(!functions || functions.length === 0) && !selectedFunction ? (
              <TimedOut global={global} event={event} />
            ) : (
              <div className="wrap-evento">
                <div className="column-left">
                  <EventBanner
                    imgEvent={this.state.imgEvent}
                    eventId={this.state.eventId}
                    event={event}
                    setShowModal={this.setShowModal}
                    shortUrl={this.shortUrl}
                    eventData={eventData}
                    selectedFunction={selectedFunction}
                    hourFormat={global.formatoFecha}
                    hourUnit={global.hourUnit}
                    locationsNum={locations.length || 0}
                  />
                  <div className="entradas-precios">
                    {selectedFunction ? (
                      <>
                        <div
                          className="place d-flex align-items-center"
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <Place />
                          <span>{selectedFunction.RecintoNombre}</span>
                        </div>

                        <span className="tipo-funcion">
                          {selectedFunction.SalaNombre}
                        </span>
                      </>
                    ) : (
                      <div
                        className="place d-flex align-items-center"
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <Place />
                        <span>{eventData.venue}</span>
                      </div>
                    )}

                    {!selectedFunction ? (
                      <div
                        className="desde-hasta"
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <p
                          className="desde"
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          {eventData.to ? <span>Desde: </span> : null}
                          <b>
                            {eventData &&
                              moment(eventData.from).format("dddd DD MMMM")}
                          </b>
                        </p>
                        {eventData.to ? (
                          <p
                            className="hasta"
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Hasta:{" "}
                            <b>
                              {eventData &&
                                moment(eventData.to).format("dddd DD MMMM")}
                            </b>
                          </p>
                        ) : null}
                      </div>
                    ) : null}

                    {!locations.length > 1 && selectedFunction ? (
                      <>
                        {this.isMultiCity() ? (
                          <p className="tipo-funcion">
                            {selectedFunction
                              ? selectedFunction.CiudadNombre
                              : locations.length === 1
                              ? eventData.city
                              : ""}
                          </p>
                        ) : null}

                        <p className="tipo-funcion">
                          {selectedFunction &&
                            `${moment
                              .utc(selectedFunction.Fecha)
                              .format(
                                global.formatoFecha === "12hr"
                                  ? "hh:mm A"
                                  : "HH:mm"
                              )} ${
                              global.formatoFecha === "12hr" ? "" : " hrs "
                            }`}
                        </p>
                      </>
                    ) : null}

                    {this.isMultiCity() ? (
                      <span className="tipo-funcion">
                        {selectedFunction
                          ? selectedFunction.RecintoNombre
                          : locations.length === 1
                          ? eventData.venue
                          : ""}
                      </span>
                    ) : null}

                    {functions && functions.length > 0 && (
                      <Calendar
                        isCalendarMultiple={
                          functions.length > global.CantidadCalendarioMin
                        }
                        functions={functions}
                        global={global}
                        isMultiCity={this.isMultiCity()}
                        selectedFunction={selectedFunction}
                        hourFormat={global.formatoFecha}
                        hourUnit={global.hourUnit}
                        isLoaded={global.isLoaded}
                        onClickItem={setFunction}
                        language={global.Idioma}
                      />
                    )}
                    {/* {functions && (
                                            <CalendarFix
                                                isCalendarMultiple={
                                                    functions.length >
                                                    global.CantidadCalendarioMin
                                                }
                                                functions={functions}
                                                isMultiCity={this.isMultiCity()}
                                                event={event}
                                                global={global}
                                                eventData={eventData}
                                                selectedFunction={
                                                    selectedFunction
                                                }
                                                hourFormat={global.formatoFecha}
                                                hourUnit={global.hourUnit}
                                                onClickItem={setFunction}
                                                thousandSeparator={
                                                    global.thousandsSeparator
                                                }
                                                decimalSeparator={
                                                    global.decimalSeparator
                                                }
                                            />
                                        )} */}
                    {selectedFunction ? (
                      selectedFunction.Gratuito == true ? (
                        <div className="precio">
                          <span className="color-texto-contenido"></span>
                          <b>Gratis</b>
                        </div>
                      ) : (
                        <div className="precio">
                          {selectedFunction.Iso === "VES" &&
                            global.CambioDeMoneda && (
                              <span
                                className="change-currency"
                                onClick={() => this.convertCurrency()}
                              >
                                {`Comprar en ${
                                  global.MonedaPorDefecto === "VES"
                                    ? "Dolares"
                                    : "Bolívares"
                                }`}
                              </span>
                            )}
                          <span className="color-texto-contenido">
                            {selectedFunction.MonedaSimbolo
                              ? event.SubBotonTitle &&
                                event.SubBotonTitle.trim() !== ""
                                ? event.SubBotonTitle.toUpperCase()
                                : this.props.t("from").toUpperCase()
                              : ""}
                          </span>
                          <b>
                            {!global.conversion
                              ? `${selectedFunction.MonedaSimbolo || ""} ${
                                  selectedFunction.MenorPrecio &&
                                  currencyFormatDE(
                                    selectedFunction.MenorPrecio,
                                    global.thousandsSeparator,
                                    global.decimalSeparator,
                                    global.MostrarDecimales
                                  )
                                } ${
                                  eventData && !eventData.currencyMulti
                                    ? ""
                                    : selectedFunction.Iso
                                }`
                              : selectedFunction.MenorPrecio &&
                                `$ ${currencyFormatDE(
                                  selectedFunction.MenorPrecioUsd,
                                  global.thousandSeparator,
                                  global.decimalSeparator,
                                  global.MostrarDecimales
                                )} ${global.MonedaPorDefecto}`}
                          </b>
                        </div>
                      )
                    ) : eventData && !eventData.currencyMulti ? (
                      <div className="precio">
                        <span className="color-texto-contenido">
                          {eventData.currencySymbol
                            ? event.SubBotonTitle &&
                              event.SubBotonTitle.trim() !== ""
                              ? event.SubBotonTitle.toUpperCase()
                              : this.props.t("from").toUpperCase()
                            : ""}
                        </span>
                        <b>{`${eventData.currencySymbol || ""} ${
                          eventData.priceFrom !== "" &&
                          eventData.priceFrom !== null
                            ? currencyFormatDE(
                                eventData.priceFrom,
                                global.housandSeparator,
                                global.decimalSeparator,
                                global.MostrarDecimales
                              )
                            : ""
                        }`}</b>
                      </div>
                    ) : null}
                    <span className="fixed-in-movil">
                      <Link
                        to={
                          selectedFunction
                            ? `/show/${nameToUrl}/${selectedFunction.FuncionId}`
                            : "#"
                        }
                      >
                        <button
                          style={{lineHeight: `45px`}}
                          className={`button-book btn-ppal ${
                            selectedFunction && !selectedFunction.Agotado
                              ? "active"
                              : ""
                          }`}
                          disabled={
                            selectedFunction && selectedFunction.Agotado
                          }
                        >
                          {selectedFunction && selectedFunction.Agotado
                            ? this.props.t("soldOut")
                            : event.BotonEventoTitle &&
                              event.BotonEventoTitle.trim() !== ""
                            ? event.BotonEventoTitle
                            : this.props.t("buyTickets")}
                        </button>
                      </Link>
                    </span>
                  </div>

                  {functions[0] &&
                  functions[0].DescripcionCorta &&
                  functions[0].DescripcionCorta.trim() !== "" ? (
                    <>
                      <div className="notas">
                        <h5 className="sub-title-bold">
                          {this.props.t("note")}
                        </h5>
                        <p className="color-texto-contenido">
                          {functions[0].DescripcionCorta}
                        </p>
                      </div>
                    </>
                  ) : null}

                  {this.state.config &&
                  this.state.config.ProductoraInfo &&
                  this.state.config.ProductoraInfo.length > 0
                    ? this.state.config.ProductoraInfo.map((lInfo) => (
                        <div className="info-importante">
                          <div className="item-container">
                            <img src={lInfo.ico} alt="" />
                            <div>
                              <h2>{lInfo.title}</h2>
                              <div
                                dangerouslySetInnerHTML={{ __html: lInfo.text }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}

                  {this.state.config &&
                  this.state.config.LateralInfo &&
                  this.state.config.LateralInfo.length > 0
                    ? this.state.config.LateralInfo.map((lInfo,key) =>
                        lInfo.title ? (
                          <div className="info-importante" key={key}>
                            <div className="item-container">
                              <img src={lInfo.ico} alt="" />
                              <div>
                                <h2>{lInfo.title}</h2>
                                {console.log(lInfo.text)}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: lInfo.text,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        ) : null
                      )
                    : null}

                  {this.state.config &&
                  this.state.config.Attributes &&
                  this.state.config.Attributes.length ? (
                    <div className="atrributes-extras">
                      {this.state.config.Attributes.map((att) => (
                        <div className="attr-container">
                          <img
                            src={att.ico}
                            alt=""
                            style={{ width: "50px", color: "#353B47" }}
                          />
                          <p>
                            {att.name}
                            {att.desc ? <span>{att.desc}</span> : null}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : null}

                  <div className="descripcion">
                    <div className="desc color-texto-contenido">
                      <div
                        id="titleDescription"
                        className="title sub-title-bold color-titulos"
                      >
                        {event.DescripcionTitulo &&
                        event.DescripcionTitulo.trim() !== ""
                          ? event.DescripcionTitulo
                          : this.props.t("descriptionTitleVenue")}
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: event.Descripcion.replaceAll(
                            "[br]",
                            "<br />"
                          ),
                        }}
                      ></p>
                    </div>
                  </div>

                  {this.state.config &&
                  this.state.config.AdditionalInfo &&
                  this.state.config.AdditionalInfo.length ? (
                    <div className="additional-info">
                      {this.state.config.AdditionalInfo.map((add, idx) => (
                        <div
                          className={`additional-container ${
                            !this.state.openAdditional.includes(add.name)
                              ? "active"
                              : ""
                          }`}
                        >
                          <h3 onClick={() => this.handleAdditional(add.name)}>
                            <span>{add.name}</span>
                            <button
                              
                            >
                              <AngleUp />
                            </button>
                          </h3>
                          <div
                            className="content-add"
                            dangerouslySetInnerHTML={{
                              __html: add.text,
                            }}
                          ></div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {locations.length > 0 && locations[0].trim() !== "" ? (
                    <div className="como-llegar">
                      <div className="title sub-title-bold color-titulos d-flex align-items-center">
                        <span>¿Cómo Llegar?</span>
                      </div>
                      <div
                        style={{
                          marginBottom: "20px",
                          marginTop: "20px",
                        }}
                      >
                        {locations.length > 1 ? (
                          selectedFunction ? (
                            <>
                              <div
                                className="place d-flex align-items-center recintoF"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                <Map />
                                <span
                                  style={{
                                    display: "inline-block",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {selectedFunction.RecintoNombre}
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              className="place d-flex align-items-center recintoF"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              <Map />
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "5px",
                                }}
                              >
                                {this.props.t("multipleLocations")}
                              </span>
                            </div>
                          )
                        ) : (
                          <p className="tipo-funcion">
                            <div
                              className="place d-flex align-items-center recintoF"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              <Map />
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "5px",
                                }}
                              >
                                {eventData.venue}
                              </span>
                            </div>
                          </p>
                        )}

                        <p className="color-texto-contenido">
                          {selectedFunction
                            ? `${selectedFunction.Calle1 || ""} ${
                                selectedFunction.Calle2 || ""
                              }`
                            : null}
                        </p>
                      </div>
                    
                      {selectedFunction ? (
                        <MapContainer
                          locations={locations}
                          selectedFunction={selectedFunction}
                          isMultiCity={this.isMultiCity()}
                          eventData={eventData}
                        />
                      ) : (
                        <MapContainer2
                          locations={locations}
                          selectedFunction={selectedFunction}
                          isMultiCity={this.isMultiCity()}
                          eventData={eventData}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="column-right">
                  <div
                    style={{
                      position: "sticky",
                      top: "30px",
                      zIndex: 9999,
                    }}
                  >
                    <div className="entradas-precios">
                      <span>
                        {locations.length > 1 ? (
                          selectedFunction ? (
                            <>
                              <div
                                className="place d-flex align-items-center recintoF"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                <Place />
                                <span>{selectedFunction.RecintoNombre}</span>
                              </div>
                              <span className="tipo-funcion salaname">
                                {selectedFunction.SalaNombre}
                              </span>
                            </>
                          ) : (
                            <div
                              className="place d-flex align-items-center recintoF"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              <Place />
                              <span>{this.props.t("multipleLocations")}</span>
                            </div>
                          )
                        ) : (
                          <p className="tipo-funcion">
                            <div
                              className="place d-flex align-items-center recintoF"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              <Place />
                              <span>{eventData.venue}</span>
                            </div>
                            {selectedFunction ? (
                              <span className="tipo-funcion salaname">
                                {selectedFunction.SalaNombre}
                              </span>
                            ) : null}
                          </p>
                        )}

                        {!selectedFunction ? (
                          <div
                            className="desde-hasta"
                            style={{
                              marginTop: "20px",
                            }}
                          >
                            <p
                              className="desde"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              {eventData.to ? <span>Desde: </span> : null}
                              <b>
                                {eventData &&
                                  moment(eventData.from).format("dddd DD MMMM")}
                              </b>
                            </p>
                            {eventData.to ? (
                              <p
                                className="hasta"
                                style={{
                                  marginBottom: "5px",
                                }}
                              >
                                Hasta:{" "}
                                <b>
                                  {eventData &&
                                    moment(eventData.to).format("dddd DD MMMM")}
                                </b>
                              </p>
                            ) : null}
                          </div>
                        ) : null}

                        {!locations.length > 1 && selectedFunction ? (
                          <>
                            {this.isMultiCity() ? (
                              <p className="tipo-funcion city">
                                {selectedFunction
                                  ? selectedFunction.CiudadNombre
                                  : locations.length === 1
                                  ? eventData.city
                                  : ""}
                              </p>
                            ) : null}

                            <p className="tipo-funcion">
                              {selectedFunction &&
                                `${moment
                                  .utc(selectedFunction.Fecha)
                                  .format(
                                    global.formatoFecha === "12hr"
                                      ? "hh:mm A"
                                      : "HH:mm"
                                  )} ${
                                  global.formatoFecha === "12hr" ? "" : " hrs "
                                }`}
                            </p>
                          </>
                        ) : null}

                        {functions && functions.length > 0 && (
                          <Calendar
                            isCalendarMultiple={
                              functions.length > global.CantidadCalendarioMin
                            }
                            functions={functions}
                            global={global}
                            isMultiCity={this.isMultiCity()}
                            selectedFunction={selectedFunction}
                            hourFormat={global.formatoFecha}
                            hourUnit={global.hourUnit}
                            isLoaded={global.isLoaded}
                            onClickItem={setFunction}
                            language={global.Idioma}
                          />
                        )}
                        {functions && (
                          <CalendarFix
                            isCalendarMultiple={
                              functions.length > global.CantidadCalendarioMin
                            }
                            functions={functions}
                            isMultiCity={this.isMultiCity()}
                            event={event}
                            global={global}
                            eventData={eventData}
                            selectedFunction={selectedFunction}
                            hourFormat={global.formatoFecha}
                            hourUnit={global.hourUnit}
                            onClickItem={setFunction}
                            thousandSeparator={global.thousandsSeparator}
                            decimalSeparator={global.decimalSeparator}
                          />
                        )}
                      </span>
                      <span>
                        {selectedFunction ? (
                          selectedFunction.Gratuito ? (
                            <div className="precio">
                              <span className="color-texto-contenido"></span>
                              <b>Gratis</b>
                            </div>
                          ) : (
                            <div className="precio">
                              {selectedFunction.Iso === "VES" &&
                                global.CambioDeMoneda && (
                                  <span
                                    className="change-currency"
                                    onClick={() => this.convertCurrency()}
                                  >
                                    {`Comprar en ${
                                      global.MonedaPorDefecto === "VES"
                                        ? "Dolares"
                                        : "Bolívares"
                                    }`}
                                  </span>
                                )}
                              <span className="color-texto-contenido">
                                {selectedFunction.MonedaSimbolo
                                  ? event.SubBotonTitle &&
                                    event.SubBotonTitle.trim() !== ""
                                    ? event.SubBotonTitle.toUpperCase()
                                    : this.props.t("from").toUpperCase()
                                  : ""}
                              </span>
                              <b>
                                {selectedFunction.Iso === "VES"
                                  ? global.MonedaPorDefecto === "VES"
                                    ? `${selectedFunction.MonedaSimbolo || ""} 
                                                                      ${
                                                                        selectedFunction.MenorPrecio &&
                                                                        currencyFormatDE(
                                                                          selectedFunction.MenorPrecio,
                                                                          global.thousandsSeparator,
                                                                          global.decimalSeparator,
                                                                          global.MostrarDecimales
                                                                        )
                                                                      } ${
                                        eventData && !eventData.currencyMulti
                                          ? ""
                                          : selectedFunction.Iso
                                      }`
                                    : selectedFunction.MenorPrecio &&
                                      `$ ${currencyFormatDE(
                                        currencyFormatValue(
                                          global.dolarToday,
                                          selectedFunction.MenorPrecio,
                                          global.MostrarDecimales
                                        ),
                                        global.thousandSeparator,
                                        global.decimalSeparator,
                                        global.MostrarDecimales
                                      )} ${global.MonedaPorDefecto}`
                                  : `${selectedFunction.MonedaSimbolo || ""} ${
                                      selectedFunction.MenorPrecio &&
                                      currencyFormatDE(
                                        selectedFunction.MenorPrecio,
                                        global.housandSeparator,
                                        global.decimalSeparator,
                                        global.MostrarDecimales
                                      )
                                    } ${
                                      eventData && !eventData.currencyMulti
                                        ? ""
                                        : selectedFunction.Iso
                                    }`}
                              </b>
                            </div>
                          )
                        ) : eventData && !eventData.currencyMulti ? (
                          <div className="precio">
                            <span className="color-texto-contenido">
                              {eventData.currencySymbol
                                ? event.SubBotonTitle &&
                                  event.SubBotonTitle.trim() !== ""
                                  ? event.SubBotonTitle.toUpperCase()
                                  : this.props.t("from").toUpperCase()
                                : ""}
                            </span>
                            <b>{`${eventData.currencySymbol || ""} ${
                              eventData.priceFrom !== "" &&
                              eventData.priceFrom !== null
                                ? currencyFormatDE(
                                    eventData.priceFrom,
                                    global.housandSeparator,
                                    global.decimalSeparator,
                                    global.MostrarDecimales
                                  )
                                : ""
                            }`}</b>
                          </div>
                        ) : null}
                        <Link
                          to={
                            selectedFunction
                              ? `/show/${nameToUrl}/${selectedFunction.FuncionId}`
                              : "#"
                          }
                        >
                          <button
                            style={{lineHeight: `45px`}}
                            className={`button-book btn-ppal ${
                              selectedFunction && !selectedFunction.Agotado
                                ? "active"
                                : ""
                            }`}
                            disabled={
                              selectedFunction && selectedFunction.Agotado
                            }
                          >
                            {selectedFunction && selectedFunction.Agotado
                              ? this.props.t("soldOut")
                              : event.BotonEventoTitle &&
                                event.BotonEventoTitle.trim()
                              ? event.BotonEventoTitle
                              : this.props.t("buyTickets")}
                          </button>
                        </Link>
                      </span>
                    </div>
                    {this.state.config &&
                    this.state.config.ProductoraInfo &&
                    this.state.config.ProductoraInfo.length > 0
                      ? this.state.config.ProductoraInfo.map((lInfo) => (
                          <div className="info-importante">
                            <div className="item-container">
                              <img src={lInfo.ico} alt="" />
                              <div>
                                <h2>{lInfo.title}</h2>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: lInfo.text,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                    {this.state.config &&
                    this.state.config.AdditionalNotes &&
                    this.state.config.AdditionalNotes.length > 0
                      ? this.state.config.AdditionalNotes.map((AdNote) => (
                        AdNote.name != null && AdNote.text != null ?
                          <>
                            <div className="notas">
                              <h5 className="sub-title-bold">{AdNote.name}</h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: AdNote.text,
                                }}
                              ></div>
                            </div>
                            <br />
                          </>
                          :
                          <></>
                        ))
                      : null}

                    {this.state.config &&
                    this.state.config.LateralInfo &&
                    this.state.config.LateralInfo.length > 0
                      ? this.state.config.LateralInfo.map((lInfo) =>
                          lInfo.title ? (
                            <div className="info-importante">
                              <div className="item-container">
                                <img src={lInfo.ico} alt="" />
                                <div>
                                  <h2>{lInfo.title}</h2>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: lInfo.text,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          ) : null
                        )
                      : null}

                    {functions[0] &&
                    functions[0].DescripcionCorta &&
                    functions[0].DescripcionCorta.trim() !== "" ? (
                      <>
                        <div className="notas">
                          <h5 className="sub-title-bold">
                            {this.props.t("note")}
                          </h5>
                          <p className="color-texto-contenido">
                            {functions[0].DescripcionCorta}
                          </p>
                        </div>
                        <br />
                      </>
                    ) : null}
                    {/* <div
                                        className="map"
                                        style={{
                                            display:
                                                (functions &&
                                                    functions.length > 0) ||
                                                selectedFunction
                                                    ? "block"
                                                    : "none",
                                        }}
                                    >
                                        <div className="hidden-xs visible-md visible-lg">
                                            <div className="title sub-title-bold color-titulos d-flex align-items-center">
                                                <svg
                                                    width="16"
                                                    height="22"
                                                    viewBox="0 0 16 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    <path
                                                        d="M14.8257 8.46779C14.8257 4.68614 11.7307 1.62061 7.91278 1.62061C4.09491 1.62061 1 4.68614 1 8.46779C1 13.5131 7.91278 19.9999 7.91278 19.9999C7.91278 19.9999 14.8257 13.5131 14.8257 8.46779Z"
                                                        stroke="var(--c1)"
                                                        strokeWidth="1.5"
                                                    />
                                                    <ellipse
                                                        cx="7.91266"
                                                        cy="8.54955"
                                                        rx="3.09259"
                                                        ry="3.06322"
                                                        stroke="var(--c1)"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                                {this.props.t("place")}
                                            </div>
                                            <p className="color-texto-contenido">
                                                <b>{eventData.venue}</b> <br />
                                                {selectedFunction &&
                                                    `${selectedFunction.SalaNombre}`}{" "}
                                                <br />
                                                {locations.length > 1 &&
                                                this.isMultiCity() &&
                                                !selectedFunction
                                                    ? "Multiples ciudades"
                                                    : selectedFunction
                                                    ? `${
                                                          selectedFunction.Calle1 ||
                                                          ""
                                                      } ${
                                                          selectedFunction.Calle2 ||
                                                          ""
                                                      }`
                                                    : `${eventData.address}`}
                                            </p>
                                        </div>
                                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        <Footer />

        {(global.loading || loading) && <SkeletonHome />}
        <ModalShare
          linkFunc={this.state.linkFunc}
          show={this.state.showModal}
          handleClose={() => {
            this.setShowModal(false);
          }}
        />
      </>
    );
  }
}

Home.propTypes = {
  getEvent: PropTypes.func.isRequired,
};

export default withTranslation()(Home);
